@import '~antd/dist/antd.less';

.version-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  color: rgba(195, 195, 195, 0.668);
  padding: 0.5rem;
}

@white: #ffffff;@primary-color: #FF2556;@secondary-color: #d75c19;@votim-blue: #182945;@text-color: @votim-blue;@medium-grey: #4b5d6c;@heading-color: @text-color;@body-background: #F5F9FA;@layout-body-background: @body-background;@layout-header-color: @white;@layout-header-padding: 0 10px 0 0;@layout-sider-background: @white;@layout-sider-color: @medium-grey;@layout-trigger-background: @layout-sider-background;@typography-title-margin-top: 0;@typography-title-margin-bottom: 0;@menu-bg: @layout-sider-background;@menu-item-color: @medium-grey;@layout-trigger-color: @medium-grey;@comment-padding-base: 0;